import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import * as CryptoJS from 'crypto-js';
import { LocalStorageService } from 'ngx-webstorage';
import { Subject } from 'rxjs';
import { Configuration } from 'src/app/app.constants';
import { User } from 'src/app/features/usermanagement/user/user.model';
import { setUserData, setUserPermissions } from 'src/app/root-store/user-data/user-action';
import { selectUserData, selectUserPermissions } from 'src/app/root-store/user-data/user-selector';
import { AccessGroupPermissionService } from '../../features/usermanagement/accessgrouppermission/components/accessgrouppermission.http.service';
import { RelUserRolePermissions } from '../../features/usermanagement/role/role.module';
import { VenueService } from '../../features/venuemanagement/venue/components/venue.http.service';
import { UtilitiesService } from '../../shared/components/utilityservices/utilityservice';
import { LoaderService } from '../../shared/directives/loader.service';
import { Login, StateMaintain } from '../models/common.model';
// import { User } from '../models/user.model';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class AuthState {
  public stateMaintains: StateMaintain[] = [];
  public isAuthenticated: Subject<boolean> = new Subject<boolean>();
  public userInfoChanged: Subject<string> = new Subject<string>();
  private isAuthenticatedVal: boolean;
  userPermissions: RelUserRolePermissions[];
  currentUser: User;

  public constructor(
    private router: Router,
    private localSt: LocalStorageService,
    private configuration: Configuration,
    private utilitiesService: UtilitiesService,
    private loaderService: LoaderService,
    public venueService: VenueService,
    private accessGroupPermissionService: AccessGroupPermissionService,
    private readonly store: Store,
  ) {
    this.isAuthenticated.subscribe((value) => {
      this.isAuthenticatedVal = value;
    });
    this.store
      .select(selectUserPermissions)
      .pipe(untilDestroyed(this))
      .subscribe((x: RelUserRolePermissions[]) => {
        this.userPermissions = x;
      });
    this.store
      .select(selectUserData)
      .pipe(untilDestroyed(this))
      .subscribe((x: User) => {
        this.currentUser = x;
      });
  }

  public getCurrentUser() {
    return this.currentUser;
  }

  public getUserPermissions() {
    return this.userPermissions;
  }

  public logout() {
    this.isAuthenticated.next(false);
    this.configuration.Token = '';
    this.localSt.clear('traxcurrentuser');
    this.localSt.clear('traxtoken');

    this.localSt.clear('traxcontextserviceproviderid');
    this.localSt.clear('serviceproviderkey');

    this.localSt.clear('traxContextCorporationId');
    this.localSt.clear('corporationkey');

    this.localSt.clear('traxContextVenueId');
    this.localSt.clear('traxuservenueid');
    this.localSt.clear('tempvenueid');
    this.localSt.clear('venuekey');

    this.localSt.clear('LocationUserAssignment');
    this.localSt.clear('ShiftStaffSchedule');
    this.localSt.clear('EitherOneOrThese');

    this.localSt.clear('EnableTouchlessFeedback');
    this.localSt.clear('EnableTouchlessFeedbackReason');

    this.localSt.clear('LogoData');
    this.localSt.clear('InspectionDate');
    this.localSt.clear('isAllowToShareUser');
    this.localSt.clear('traxuserzoneid');
    this.localSt.clear('subscriptiontype');
    this.localSt.clear('venueFeaturesConfig');
    this.localSt.clear('spLicenseExpiryDate');
    this.localSt.clear('ipaddress');
    this.localSt.clear('isBadgeEnable');
    this.clearPageState();

    this.configuration.isUnauthorizedState = true;
    this.store.dispatch(setUserData({ user: null }));
  }

  public getModulesData() {
    this.utilitiesService.isAreaMenuShow = this.utilitiesService.checkVenueFeatureConfiguration('VENUE_TAG_AREA');
  }

  /**
   * can activate method in auth guard to check the user access
   */
  async canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // if isAuthenticatedVal=true & isUnauthorizedState=false then go to home else logout
    if (this.isAuthenticatedVal && !this.configuration.isUnauthorizedState) {
      if (state.url.replace('/', '') === 'home') {
        return true;
      }
      return this.canAccess(state.url.split('/')[1]);
    } else {
      // Navigate to login if the route is login
      this.configuration.isUnauthorizedState = true;

      if (state.url.replace('/', '').indexOf('login') > -1) {
        return true;
      } else {
        // check whether the user is already login using the locally stored values
        // Redirect to the route if user is logged in else navigate to Login
        await this.CheckLogin(state.url);
        if (this.isAuthenticatedVal) {
          return true;
        } else {
          this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
          return false;
        }
      }
    }
  }

  /**
   * Get the current user session and set data
   */
  public getUserSession = (): User => {
    if (this.getStoredTraxCurrentUser() && this.getStoredTraxToken()) {
      this.configuration.Token = this.getStoredTraxToken();
      this.configuration.userVenueId = this.getStoredUserVenue();
      this.configuration.contextServiceProviderId = this.getStoredContextServiceProviderId();
      this.configuration.contextCorporationId = this.getStoredContextCorporationId();
      this.configuration.contextVenueId = this.getStoredContextVenueId();

      this.configuration.isUnauthorizedState = false;
      return this.getStoredTraxCurrentUser();
    }
    return null;
  };

  public canAccess(feature: string) {
    if (feature === 'configure-default-venue' || feature === 'user-billing-custom-report') {
      return true;
    } else if (this.userPermissions.length) {
      const matchingPermission = this.userPermissions.find(
        (permission) => permission.Permission.PermissionName === feature,
      );
      if (matchingPermission) {
        return matchingPermission.PAccess > 0;
      }

      if (
        this.currentUser.UserApplicationLevel === 'ADMIN' &&
        ('country' === feature ||
          'state' === feature ||
          'city' === feature ||
          'task' === feature ||
          'inventory' === feature ||
          'element' === feature ||
          'sensor-type' === feature ||
          'sensor-manufacturer' === feature ||
          'survey-question-configuration' === feature ||
          'survey-question-response-configuration' === feature ||
          'user-billing-custom-report' === feature ||
          'subscription' === feature ||
          'inspection-score-factor-configuration' === feature)
      ) {
        return true;
      }
    }
    return false;
  }

  /**
   * Method to check the feature access
   */
  public canAccessFeature(userRolePermissions: RelUserRolePermissions[], feature: string) {
    return userRolePermissions.some(
      (permission) => permission.Permission.PermissionName === feature && permission.PAccess > 0,
    );
  }

  /**
   * Method to check whether user has the passed feature permission.
   */
  getAccessPermission(userRolePermissions: RelUserRolePermissions[], feature: string) {
    return userRolePermissions.some((permission) => permission.Permission.PermissionName === feature);
  }

  public setUserRolePermissions(userRolePermissions: RelUserRolePermissions[]) {
    this.store.dispatch(setUserPermissions({ permissions: userRolePermissions }));
  }

  /**
   * Method to store the page state
   */
  public storePageState(pageState: StateMaintain) {
    this.stateMaintains = this.localSt.retrieve('StateMaintain') || [];
    let matchingElement = this.stateMaintains.find((sm) => sm.pageName === pageState.pageName);

    if (matchingElement) {
      matchingElement = pageState;
    } else {
      this.stateMaintains.push(pageState);
    }
    this.localSt.store('StateMaintain', this.stateMaintains);
  }

  /**
   * Method to retrieve the stored page state
   */
  public retrievePageState(pageState: StateMaintain): StateMaintain {
    const stateMaintains: StateMaintain[] = this.localSt.retrieve('StateMaintain') || [];
    if (!stateMaintains.length) {
      return pageState;
    }
    const matchingState = stateMaintains.find((stateMaintain) => stateMaintain.pageName === pageState.pageName);
    return matchingState || pageState;
  }

  public clearPageState() {
    this.localSt.clear('StateMaintain');
    this.localSt.clear('signalr');
    this.localSt.clear('gkey');
  }

  // The set method is use for encrypt the value.
  AESEncryptText(value): any {
    if (value != null) {
      const keys = this.configuration.pw1 + this.configuration.pw2;
      const key = CryptoJS.enc.Utf8.parse(keys);
      const iv = CryptoJS.enc.Utf8.parse(keys);
      const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(value.toString()), key, {
        keySize: 256,
        iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      });

      return encrypted.toString();
    } else {
      return '';
    }
  }

  // The get method is use for decrypt the value.
  AESDecryptText(value): any {
    if (value != null) {
      const keys = this.configuration.pw1 + this.configuration.pw2;
      const key = CryptoJS.enc.Utf8.parse(keys);
      const iv = CryptoJS.enc.Utf8.parse(keys);
      const decrypted = CryptoJS.AES.decrypt(value, key, {
        keySize: 256,
        iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      });
      return decrypted.toString(CryptoJS.enc.Utf8);
    } else {
      return '';
    }
  }

  // store, retrieve and clear 'Service Provider'
  public storedContextServiceProviderId(contextServiceProviderId: number) {
    this.localSt.clear('traxContextServiceProviderId');
    this.localSt.store('traxContextServiceProviderId', contextServiceProviderId);
  }

  public storedServiceProviderName(serviceProviderName: string) {
    this.localSt.clear('serviceProviderKey');
    this.localSt.store('serviceProviderKey', serviceProviderName);
  }

  public getStoredContextServiceProviderId() {
    return this.localSt.retrieve('traxContextServiceProviderId');
  }

  public getStoredServiceProviderName() {
    return this.localSt.retrieve('serviceProviderKey');
  }

  // store, retrieve and clear 'Corporation'
  public storedContextCorporationId(contextCorporationId: number) {
    this.localSt.clear('traxContextCorporationId');
    this.localSt.store('traxContextCorporationId', contextCorporationId);
  }

  public storedCorporationName(corporationName: string) {
    this.localSt.clear('corporationKey');
    this.localSt.store('corporationKey', corporationName);
  }

  public getStoredContextCorporationId() {
    return this.localSt.retrieve('traxcontextcorporationid');
  }

  public getStoredCorporationName() {
    return this.localSt.retrieve('corporationKey');
  }

  // store, retrieve and clear 'Venue'
  public storedContextVenueId(contextVenueId: number) {
    this.localSt.clear('traxContextVenueId');
    this.localSt.store('traxContextVenueId', contextVenueId);
  }

  public storedVenueName(venueName: string) {
    this.localSt.clear('VenueKey');
    this.localSt.store('VenueKey', venueName);
  }

  public getStoredContextVenueId() {
    return this.localSt.retrieve('traxcontextvenueid');
  }

  public getStoredVenueName() {
    return this.localSt.retrieve('VenueKey');
  }

  // store, retrieve and clear 'Temp Venue'
  public storedTempVenueId(tempVenueId: number) {
    this.localSt.clear('tempVenueId');
    this.localSt.store('tempVenueId', tempVenueId);
  }

  public getStoredTempVenueId() {
    return this.localSt.retrieve('tempVenueId');
  }

  // store, retrieve and clear 'User Zone'
  public storeUserZoneId(userZoneId: number) {
    this.localSt.clear('traxuserzoneid');
    return this.localSt.store('traxuserzoneid', userZoneId);
  }

  public getStoredUserZone() {
    return this.localSt.retrieve('traxuserzoneid');
  }

  public clearUserZone() {
    this.localSt.clear('traxuserzoneid');
  }

  // store, retrieve and clear 'User Venue'
  public storeUserVenueId(userVenueId: number) {
    this.localSt.clear('traxuservenueid');
    return this.localSt.store('traxuservenueid', userVenueId);
  }

  public getStoredUserVenue() {
    return this.localSt.retrieve('traxuservenueid');
  }

  public clearUserVenue() {
    this.localSt.clear('traxuservenueid');
  }

  // store, retrieve and clear 'User Email'
  public storeUserEmail(userEmail: string) {
    this.localSt.clear('traxuseremail');
    return this.localSt.store('traxuseremail', userEmail);
  }

  public storeActualLoggedInUserEmail(userEmail: string) {
    this.localSt.clear('loggedInUserEmail');
    return this.localSt.store('loggedInUserEmail', userEmail);
  }

  public getStoredActualLoggedInUserEmail() {
    return this.localSt.retrieve('loggedInUserEmail');
  }

  public getStoredUserEmail() {
    return this.localSt.retrieve('traxuseremail');
  }

  public clearUserEmail() {
    this.localSt.clear('traxuseremail');
  }

  // store, retrieve and clear 'Sensor'
  public storeSensor(storeSensor: any) {
    this.localSt.clear('store-sensor');
    this.localSt.store('store-sensor', storeSensor);
  }

  public getStoredStoreSensor() {
    return this.localSt.retrieve('store-sensor');
  }

  // store, retrieve and clear 'Current User'
  public storedTraxCurrentUser(currentUser: any) {
    this.localSt.clear('traxcurrentuser');
    this.localSt.store('traxcurrentuser', currentUser);
  }

  public getStoredTraxCurrentUser() {
    return this.localSt.retrieve('traxcurrentuser');
  }

  public clearTraxCurrentUser() {
    this.localSt.clear('traxcurrentuser');
  }

  // store, retrieve and clear 'Token'
  public storedTraxToken(traxtoken: any) {
    this.localSt.clear('traxtoken');
    this.localSt.store('traxtoken', traxtoken);
  }

  public getStoredTraxToken() {
    return this.localSt.retrieve('traxtoken');
  }

  public clearTraxToken() {
    this.localSt.clear('traxtoken');
  }

  public storedSubscriptionType(subscriptionType: string) {
    this.localSt.clear('subscriptionType');
    this.localSt.store('subscriptionType', subscriptionType);
  }

  public storedShiftStaffSchedule(shiftStaffSchedule: any) {
    this.localSt.clear('ShiftStaffSchedule');
    this.localSt.store('ShiftStaffSchedule', shiftStaffSchedule);
  }

  public getStoredShiftStaffSchedule() {
    return this.localSt.retrieve('ShiftStaffSchedule');
  }

  public storedLocationUserAssignment(locationUserAssignment: any) {
    this.localSt.clear('LocationUserAssignment');
    this.localSt.store('LocationUserAssignment', locationUserAssignment);
  }

  public getStoredLocationUserAssignment() {
    return this.localSt.retrieve('LocationUserAssignment');
  }

  public storedEitherOneOrThese(eitherOneOrThese: any) {
    this.localSt.clear('EitherOneOrThese');
    this.localSt.store('EitherOneOrThese', eitherOneOrThese);
  }

  public getStoredEitherOneOrThese() {
    return this.localSt.retrieve('EitherOneOrThese');
  }

  public storeLogo(logoData: any) {
    this.localSt.clear('LogoData');
    this.localSt.store('LogoData', logoData);
  }

  public getStoredLogo() {
    return this.localSt.retrieve('LogoData');
  }

  // store, retrieve and clear 'Badge'
  public storedBadge(isBadgeEnable: any) {
    this.localSt.clear('isBadgeEnable');
    this.localSt.store('isBadgeEnable', isBadgeEnable);
  }

  public getStoredBadge() {
    return this.localSt.retrieve('isBadgeEnable');
  }

  public storedSignalrURL(signalrurl: any) {
    this.localSt.clear('signalr');
    if (signalrurl != null) {
      this.localSt.store('signalr', this.AESEncryptText(signalrurl));
    }
  }

  public getSignalrURL() {
    return this.localSt.retrieve('signalr');
  }

  public storedGoogleAPIKey(googleKey: any) {
    this.localSt.clear('gkey');
    if (googleKey != null) {
      this.localSt.store('gkey', this.AESEncryptText(googleKey));
    }
  }

  public getGoogleAPIKey() {
    return this.localSt.retrieve('gkey');
  }

  public storedEnableTouchlessFeedback(touchlessFeedback: any) {
    this.localSt.clear('EnableTouchlessFeedback');
    this.localSt.store('EnableTouchlessFeedback', touchlessFeedback);
  }

  public getStoredEnableTouchlessFeedback() {
    return this.localSt.retrieve('EnableTouchlessFeedback');
  }

  public clearEnableTouchlessFeedback() {
    this.localSt.clear('EnableTouchlessFeedback');
  }

  public storedEnableTouchlessFeedbackReason(touchlessFeedbackReason: any) {
    this.localSt.clear('EnableTouchlessFeedbackReason');
    this.localSt.store('EnableTouchlessFeedbackReason', touchlessFeedbackReason);
  }

  public getStoredEnableTouchlessFeedbackReason() {
    return this.localSt.retrieve('EnableTouchlessFeedbackReason');
  }

  public clearEnableTouchlessFeedbackReason() {
    this.localSt.clear('EnableTouchlessFeedbackReason');
  }

  public storedInspectionDate(inspectionDate: any) {
    this.localSt.clear('InspectionDate');
    this.localSt.store('InspectionDate', inspectionDate);
  }

  public getStoredInspectionDate() {
    return this.localSt.retrieve('InspectionDate');
  }

  public clearInspectionDate() {
    this.localSt.clear('InspectionDate');
  }

  // store, retrieve and clear 'Allow to share user flag'
  public storedIsAllowToShareUser(isAllowToShareUser: any) {
    this.localSt.clear('isAllowToShareUser');
    this.localSt.store('isAllowToShareUser', isAllowToShareUser);
  }

  public getStoredIsAllowToShareUser() {
    return this.localSt.retrieve('isAllowToShareUser');
  }

  public clearIsAllowToShareUser() {
    this.localSt.clear('isAllowToShareUser');
  }

  // store, retrieve and clear 'SP License Expiry Date'
  public storedSPLicenseExpiryDate(isAllowToShareUser: any) {
    this.localSt.clear('spLicenseExpiryDate');
    this.localSt.store('spLicenseExpiryDate', isAllowToShareUser);
  }

  public getStoredSPLicenseExpiryDate() {
    return this.localSt.retrieve('spLicenseExpiryDate');
  }

  public clearSPLicenseExpiryDate() {
    this.localSt.clear('spLicenseExpiryDate');
  }

  // store, retrieve and clear 'IP Address'
  public storedIPAddress(ipAddress: any) {
    this.localSt.clear('ipaddress');
    this.localSt.store('ipaddress', ipAddress);
  }

  public getStoredIPAddress() {
    return this.localSt.retrieve('ipaddress');
  }

  public storeIsEmulation(flag: boolean) {
    this.localSt.clear('isEmulation');
    this.localSt.store('isEmulation', flag);
  }

  public getIsEmulation() {
    return this.localSt.retrieve('isEmulation');
  }

  /**
   * Method to check whether the user is logged in or not.
   * If the user is logged in set the permissions and venue details if they are configured.
   *
   * @returns The logged in user details
   */
  public async CheckLogin(defaultRoute: string = null): Promise<{
    user: User;
    login: Login;
    rolePermissions: RelUserRolePermissions[];
  }> {
    this.clearInspectionDate();

    this.configuration.userZoneId = this.getStoredUserZone();
    this.configuration.VenueId = this.getStoredUserVenue();

    const loggedInUser = this.getUserSession();
    const loggedInUserObj: Login = {};
    const rolePermissions: RelUserRolePermissions[] = [];

    // Retrieve email id if user check the remember me check box to auto populate
    if (this.getStoredUserEmail() !== null) {
      loggedInUserObj.username = this.getStoredUserEmail();
      loggedInUserObj.rememberMe = true;
    }

    // On reload if the user is already logged in, get the venue details and set the role permissions before navigation
    if (loggedInUser?.UserId) {
      if (this.getStoredContextVenueId()) {
        await this.getVenueById(this.getStoredContextVenueId());
        await this.setRolePermissions(loggedInUser, rolePermissions, defaultRoute);
      } else {
        await this.setRolePermissions(loggedInUser, rolePermissions, defaultRoute);
      }
    }

    return { user: loggedInUser, login: loggedInUserObj, rolePermissions };
  }

  /**
   * Method to set the configured venue details.
   */
  public async getVenueById(venueId: number) {
    this.loaderService.display(true);
    const cpVenue = { VenueCPId: this.AESEncryptText(venueId) };

    try {
      const venueDetails = await this.venueService.GetSingle(cpVenue).toPromise();
      if (venueDetails) {
        if (venueDetails.SignalRServiceURL != null && venueDetails.SignalRServiceURL !== '') {
          this.storedSignalrURL(venueDetails.SignalRServiceURL);
        }
        if (venueDetails.UserAssignmentRule != null) {
          this.storedShiftStaffSchedule(venueDetails.UserAssignmentRule.ShiftStaffSchedule);
          this.storedLocationUserAssignment(venueDetails.UserAssignmentRule.LocationUserAssignment);
          this.storedEitherOneOrThese(venueDetails.UserAssignmentRule.EitherOneOrThese);
        }
        this.storedBadge(venueDetails.IsBadgeEnable);
      }
      this.loaderService.display(false);
    } catch (error) {
      this.loaderService.display(false);
    }
  }

  /**
   * Method to get the permissions of logged in user
   * After the role permissions are set, the current user data is set and and default navigation is set
   */
  private async setRolePermissions(
    loggedInUser: User,
    rolePermissions: RelUserRolePermissions[],
    defaultRoute: string,
  ) {
    this.loaderService.display(true);
    const searchfilter = { SearchQuery: '', GenericSearch: 0 };

    try {
      rolePermissions = await this.accessGroupPermissionService
        .GetAllPermissions(JSON.stringify(searchfilter), -1, -1)
        .toPromise();
      this.loaderService.display(false);
      this.store.dispatch(setUserData({ user: loggedInUser }));
      this.isAuthenticated.next(true);
      this.setUserRolePermissions(rolePermissions);
      this.setDefaultNavigation(loggedInUser, rolePermissions, defaultRoute);
    } catch (error) {
      this.loaderService.display(false);
      if (error.status == 401) {
        this.logout();
      }
    }
  }

  /**
   * Method to set the default navigation after login
   * If venue is not configured, navigate to configure default venue screen, else check for
   * the permission to view dashboard/restroom-overview/profile-menu screens and navigate accordingly
   */
  setDefaultNavigation(loggedInUser: User, rolePermissions: RelUserRolePermissions[], defaultRoute: string) {
    if (!this.configuration.contextVenueId || loggedInUser.IsSharedUser === 1) {
      this.router.navigate(['configure-default-venue']);
    } else if (defaultRoute) {
      this.router.navigate([defaultRoute]);
    } else if (this.canAccessFeature(rolePermissions, 'dashboard')) {
      this.router.navigate(['dashboard']);
    } else if (this.canAccessFeature(rolePermissions, 'restroom-overview')) {
      this.router.navigate(['restroom-overview']);
    } else if (this.canAccessFeature(rolePermissions, 'profile-menu')) {
      this.router.navigate(['profile-menu']);
    } else {
      this.checkForPermittedScreens(rolePermissions);
    }
  }

  /**
   * Method to redirect to the default navigation if venue is configured and user do not have the access to view the screens
   * dashboard/restroom-overview/profile-menu
   */
  private checkForPermittedScreens(rolePermissions: RelUserRolePermissions[]) {
    if (this.getStoredShiftStaffSchedule() || this.getStoredEitherOneOrThese()) {
      if (this.getAccessPermission(rolePermissions, 'shift')) {
        this.router.navigate(['shift']);
      } else if (this.getAccessPermission(rolePermissions, 'shift-break-schedule')) {
        this.router.navigate(['shift-break-schedule']);
      } else if (this.getAccessPermission(rolePermissions, 'staff-schedule')) {
        this.router.navigate(['staff-schedule']);
      }
    } else {
      if (
        this.getAccessPermission(rolePermissions, 'userlocationassignment') &&
        (this.getStoredLocationUserAssignment() || this.getStoredEitherOneOrThese())
      ) {
        this.router.navigate(['userlocationassignment']);
      } else {
        const checkedPermissions = [
          'shift',
          'SHIFT_VIEW',
          'SHIFT_ADD',
          'SHIFT_EDIT',
          'SHIFT_BRK_SCHEDULE_VIEW',
          'SHIFT_BRK_SCHEDULE_ADD',
          'shift-break-schedule',
          'SHIFT_BRK_SCHEDULE_EDIT',
          'staff-schedule',
          'STAFF_SCHEDULE_VIEW',
          'STAFF_SCHEDULE_ADD',
          'STAFF_SCHEDULE_EDIT',
          'userlocationassignment',
          'LOCATION_ASGMT_VIEW_USERS_RECORDS',
          'LOCATION_ASGMT_VIEW_UNASSIGNED_LOCATION',
          'LOCATION_ASGMT_EDIT',
          'LOCATION_ASGMT_ASSIGN_SELF',
          'LOCATION_ASGMT_ASSIGN_TO_OTHERS',
        ];
        const redirectionRoute = rolePermissions.find(
          (permission) => !checkedPermissions.includes(permission.Permission.PermissionName),
        );
        if (redirectionRoute) {
          this.router.navigate([redirectionRoute.Permission.PermissionName]);
        } else {
          this.router.navigate(['configure-default-venue']);
        }
      }
    }
  }
}
