import { Component, ElementRef, HostListener, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import versions from 'package.json';
import * as Plotly from 'plotly.js/dist/plotly.js';
import { Observable, Subscription } from 'rxjs';
import { Configuration } from 'src/app/app.constants';
import { Login, UserTokenAuthentication } from 'src/app/core/models/common.model';
import { RelUserRolePermissions } from 'src/app/core/models/role.model';
import { AuthState } from 'src/app/core/services/auth.service';
import { LoginService } from 'src/app/features/login/components/login.http.service';
import { ServiceProviderService } from 'src/app/features/serviceprovider/components/serviceprovider.http.service';
import { Module, ServiceProviderModule } from 'src/app/features/serviceprovider/serviceprovider.module';
import { AccessGroupPermissionService } from 'src/app/features/usermanagement/accessgrouppermission/components/accessgrouppermission.http.service';
import { UserService } from 'src/app/features/usermanagement/user/components/user.http.service';
import { User } from 'src/app/features/usermanagement/user/user.model';
import { VenueService } from 'src/app/features/venuemanagement/venue/components/venue.http.service';
import { ClientLogo, VenueModule } from 'src/app/features/venuemanagement/venue/venue.module';
import { setUserData } from 'src/app/root-store/user-data/user-action';
import { selectUserData } from 'src/app/root-store/user-data/user-selector';
import { environment } from 'src/environments/environment';
import { Userpilot } from 'userpilot';
import { LoaderService } from '../../directives/loader.service';
import { LogoService } from '../../directives/logo.service';
import { RefreshDashBoardService } from '../../services/RefreshDashBorad.service';
import { UtilitiesService } from '../utilityservices/utilityservice';

@UntilDestroy()
@Component({
  selector: 'app-navigation',
  templateUrl: 'navigation.component.html',
  styleUrls: ['./navigation.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [UserService, ServiceProviderService, VenueService, LoginService, UtilitiesService],
})
export class NavigationComponent implements OnInit {
  public currentUser: User;

  currentUserVal: User;
  public profileImg = 'assets/blankavatar.png';
  public profileImgSrc: any = null;

  public routeTitle: string;

  public isLocationUserAssignmentShow = false;
  public isShiftStaffScheduleShow = false;
  public isEitherOneOrTheseShow = false;
  public isTouchlessFeedbackShow = false;
  public isTouchlessFeedbackReasonShow = false;
  public isAllowToShareUserShow = false;
  loggedInUserObj: Login;
  loggedInUser: User = new User();
  public rolePermissions: RelUserRolePermissions[] = [];
  isEmulated: boolean = false;
  isReqComplete = false;
  LogoutConfirmationModal = false;
  public modules: Module[] = [];

  public storedLogo: any;
  public logoName = 'assets/interior_logo.png';
  public logoNameSrc: any;
  clientLogo: ClientLogo = new ClientLogo();
  public isSysAdmin = false;
  public isEmulation = false;
  poweredBy = environment.poweredBy;
  public apiVersion: Observable<string>;
  public appVersion: string = versions.version;
  public defaultlogo = true;

  private subscription: Subscription;
  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private router: Router,
    private authState: AuthState,
    private userservice: UserService,
    public configuration: Configuration,
    public venueService: VenueService,
    private loaderService: LoaderService,
    private logoService: LogoService,
    private serviceProviderService: ServiceProviderService,
    private loginService: LoginService,
    public utilitiesService: UtilitiesService,
    private route: ActivatedRoute,
    private store: Store,
    private accessGroupPermissionService: AccessGroupPermissionService,
    private refreshService: RefreshDashBoardService,
  ) {
    this.routeTitle = this.route.root.firstChild.snapshot.url.toString().split('-')[0];
    this.currentUserVal = this.authState.getCurrentUser();
    this.isSysAdmin = this.currentUserVal.UserApplicationLevel == 'ADMIN';
    if (this.isSysAdmin) {
      this.isAllowToShareUserShow = true;
    }
    this.logoService.logostatus.subscribe((response: any) => {
      this.storedLogo = response;
      if (this.storedLogo != null || this.storedLogo != '') {
        if (this.storedLogo.ItemType == 'VENUE') {
          this.logoName = this.storedLogo.AttachmentURL;
          this.defaultlogo = false;
        } else if (this.storedLogo.ItemType == 'CORPORATION') {
          this.logoName = this.storedLogo.AttachmentURL;
          this.defaultlogo = false;
        } else if (this.storedLogo.ItemType == 'SERVICE_PROVIDER') {
          this.logoName = this.storedLogo.AttachmentURL;
          this.defaultlogo = false;
        } else {
          this.defaultlogo = true;
        }
      }
    });
  }

  ngOnInit() {
    this.checkDivVisibility();
    this.apiVersion = this.loginService.ApiVersion();
    this.getMainLogo();
    this.setLoggedInUserInfo(null);
    this.subscription = this.refreshService.refresh$.subscribe(() => {
      this.refreshData();
    });
    this.store
      .select(selectUserData)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.onUserInfoChanges();
      });

    this.configuration.CorporationName = this.authState.getStoredCorporationName();
    this.configuration.VenueName = this.authState.getStoredVenueName();

    if (this.authState.getStoredIsAllowToShareUser() != null) {
      this.isAllowToShareUserShow = true;
    }
  }

  onUserInfoChanges() {
    if (!this.configuration.isUnauthorizedState && this.currentUserVal?.UserId) {
      const CPuser = new User();
      CPuser.UserCPId = this.authState.AESEncryptText(this.currentUserVal.UserId);
      this.userservice.GetById(CPuser).subscribe((data) => {
        this.setLoggedInUserInfo(data.AttachmentURL);
        this.currentUserVal = {
          ...this.currentUserVal,
          FirstName: data.FirstName,
          LastName: data.LastName,
          AttachmentURL: data.AttachmentURL,
          ContextRoleId: data.ContextRoleId,
          ContextRoleName: data.ContextRoleName,
        };
        this.authState.storedTraxCurrentUser(this.currentUserVal);

        if (this.authState.getStoredContextServiceProviderId()) {
          this.getServiceProviderById(this.authState.getStoredContextServiceProviderId());
        }
      });
    }
  }
  refreshData(): void {
    console.log('Refreshing dashboard data...');
    this.isEmulation = true;
  }
  setLoggedInUserInfo(AttachmentURL: string) {
    if (AttachmentURL) {
      this.currentUserVal = { ...this.currentUserVal, AttachmentURL };
    }
    this.getProfileImage(this.currentUserVal.AttachmentURL);
  }

  setDefaultPic($event: any) {
    $event.target.src = this.profileImg;
  }

  logout() {
    this.LogoutConfirmationModal = true;
  }

  public signOut() {
    this.userLogout();
  }

  public CloseLogoutConfirmationModal() {
    this.LogoutConfirmationModal = false;
  }

  canAccess(feature: string, access: string) {
    return this.authState.canAccess(feature);
  }

  profiledetails() {
    this.router.navigate(['/profile-menu']);
  }

  navSlide() {
    if (window.matchMedia('(max-width: 767px)').matches) {
      const body = document.getElementsByTagName('body')[0];
      const div = document.getElementById('maincontent');
      body.classList.remove('sidebar-open');
      div.classList.remove('sidebar-open');
    }
  }

  @HostListener('mouseover') onMouseOver() {
    const part = this.el.nativeElement.querySelector('.main-sidebar');
    this.renderer.setStyle(part, 'overflow-y', 'auto');
  }

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(e) {
    let ele = document.getElementById('sidebar');
    if (ele != null) {
      let distance = ele.offsetLeft + ele.offsetWidth - e.pageX;
      distance < 5 && distance > -5 ? ele.classList.add('scroll-width') : ele.classList.remove('scroll-width');
    }

    let ele1 = document.getElementById('main-sidebar');
    if (ele1 != null) {
      let distance1 = ele1.offsetLeft + ele1.offsetWidth - e.pageX;
      distance1 < 5 && distance1 > -5 ? ele1.classList.add('scroll-width') : ele1.classList.remove('scroll-width');
    }
  }

  public configurationPopup() {
    this.router.navigate(['/configure-default-venue']);
  }

  public navigationClick(navigationUrl: any) {
    if (
      this.currentUserVal.UserApplicationLevel == 'ADMIN' &&
      (navigationUrl == 'corporation' || navigationUrl == 'venue')
    ) {
      this.router.navigate([navigationUrl]);
    } else {
      const storedVenueId = this.authState.getStoredContextVenueId();

      if (storedVenueId !== null && storedVenueId !== undefined && storedVenueId > 0) {
        this.router.navigate([navigationUrl]);
      } else {
        this.router.navigate(['/configure-default-venue']);
      }
    }

    if (window.getSelection) {
      window.getSelection().removeAllRanges();
    } else if (document.getSelection) {
      document.getSelection().empty();
    }
  }

  public getProfileImage(AttachmentURL: string) {
    // const profileimagename = AttachmentURL ? AttachmentURL : this.profileImg;
    this.profileImgSrc = AttachmentURL ? AttachmentURL : this.profileImg;
  }

  public getMainLogo() {
    this.loaderService.display(true);
    this.venueService.GetClientLogo().subscribe(
      (data) => {
        this.clientLogo = data;
        this.authState.storeLogo(this.clientLogo);

        this.storedLogo = '';
        this.storedLogo = this.authState.getStoredLogo();
        if (this.storedLogo != null) {
          if (this.storedLogo.ItemType == 'VENUE') {
            this.logoName = this.storedLogo.AttachmentURL;
            this.defaultlogo = false;
          } else if (this.storedLogo.ItemType == 'CORPORATION') {
            this.logoName = this.storedLogo.AttachmentURL;
            this.defaultlogo = false;
          } else if (this.storedLogo.ItemType == 'SERVICE_PROVIDER') {
            this.logoName = this.storedLogo.AttachmentURL;
            this.defaultlogo = false;
          } else {
            this.defaultlogo = true;
          }
        }

        this.loaderService.display(false);
        if (this.authState.getStoredContextServiceProviderId()) {
          this.getServiceProviderById(this.authState.getStoredContextServiceProviderId());
        }
      },
      (error) => {
        console.log(
          'venueService GetClientLogo Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
        this.loaderService.display(false);
        if (this.authState.getStoredContextServiceProviderId()) {
          this.getServiceProviderById(this.authState.getStoredContextServiceProviderId());
        }
      },
    );
  }

  public getServiceProviderById(serviceProviderId: number) {
    this.loaderService.display(true);
    const CPServiceProvider = new ServiceProviderModule();
    CPServiceProvider.ServiceProviderCPId = this.authState.AESEncryptText(serviceProviderId);

    this.serviceProviderService.GetSingle(CPServiceProvider).subscribe(
      (data) => {
        if (data != null) {
          if (data.AllowToShareUser != null) {
            if (data.SPLicenseExpiryDate != null) {
              this.authState.clearSPLicenseExpiryDate();
              this.authState.storedSPLicenseExpiryDate(data.SPLicenseExpiryDate.split('T')[0]);
            }

            if (data.AllowToShareUser) {
              this.isAllowToShareUserShow = true;
            } else {
              this.isAllowToShareUserShow = false;
            }
            this.authState.storedIsAllowToShareUser(data.AllowToShareUser);
          }
        }

        this.loaderService.display(false);
        if (this.authState.getStoredContextVenueId()) {
          this.getVenueById(this.authState.getStoredContextVenueId());
        }
      },
      (error) => {
        console.log(
          'serviceproviderService GetServiceProviderById Call Failed. Status:' +
            error.status +
            ' Status Text:' +
            error.statusText,
        );
        this.loaderService.display(false);
        if (this.authState.getStoredContextVenueId()) {
          this.getVenueById(this.authState.getStoredContextVenueId());
        }
      },
    );
  }

  public getVenueById(VenueId: number) {
    this.loaderService.display(true);
    const CPvenue = new VenueModule();
    CPvenue.VenueCPId = this.authState.AESEncryptText(VenueId);

    this.venueService.GetSingle(CPvenue).subscribe(
      (data) => {
        if (data != null) {
          if (
            data != null &&
            data.RelVenueMapIntegrationTypeUrls != null &&
            data.RelVenueMapIntegrationTypeUrls.length > 0
          ) {
            for (let i = 0; i < data.RelVenueMapIntegrationTypeUrls.length; i++) {
              if (
                data.RelVenueMapIntegrationTypeUrls[i].VenuemapIntegrationType.VenueMapIntegrationTypeCode ==
                'GOOGLE_MAP'
              ) {
                if (
                  data.RelVenueMapIntegrationTypeUrls[i].VenuemapIntegrationType.VenuemapIntegrationTypeKey
                    .VenueMapIntegrationTypeKeyName == 'API_KEY' &&
                  data.RelVenueMapIntegrationTypeUrls[i].VenuemapIntegrationType.VenuemapIntegrationTypeKey
                    .VenueMapIntegrationTypeKeyUrl != null &&
                  data.RelVenueMapIntegrationTypeUrls[i].VenuemapIntegrationType.VenuemapIntegrationTypeKey
                    .VenueMapIntegrationTypeKeyUrl != ''
                ) {
                  this.authState.storedGoogleAPIKey(
                    data.RelVenueMapIntegrationTypeUrls[i].VenuemapIntegrationType.VenuemapIntegrationTypeKey
                      .VenueMapIntegrationTypeKeyUrl,
                  );
                }
              }
            }
          }

          if (data.SignalRServiceURL != null && data.SignalRServiceURL != '') {
            this.authState.storedSignalrURL(data.SignalRServiceURL);
          }
          if (data.UserAssignmentRule != null) {
            this.authState.storedShiftStaffSchedule(data.UserAssignmentRule.ShiftStaffSchedule);
            this.authState.storedLocationUserAssignment(data.UserAssignmentRule.LocationUserAssignment);
            this.authState.storedEitherOneOrThese(data.UserAssignmentRule.EitherOneOrThese);

            if (data.UserAssignmentRule.LocationUserAssignment == 1) {
              this.isLocationUserAssignmentShow = true;
            } else {
              this.isLocationUserAssignmentShow = false;
            }

            if (data.UserAssignmentRule.ShiftStaffSchedule == 1) {
              this.isShiftStaffScheduleShow = true;
            } else {
              this.isShiftStaffScheduleShow = false;
            }

            if (data.UserAssignmentRule.EitherOneOrThese == 1) {
              this.isEitherOneOrTheseShow = true;
            } else {
              this.isEitherOneOrTheseShow = false;
            }
          }

          if (data.EnableTouchlessFeedback) {
            this.isTouchlessFeedbackShow = true;
          } else {
            this.isTouchlessFeedbackShow = false;
          }

          if (data.EnableTouchlessFeedbackReason) {
            this.isTouchlessFeedbackReasonShow = true;
          } else {
            this.isTouchlessFeedbackReasonShow = false;
          }

          this.authState.storedBadge(data.IsBadgeEnable);
        }

        this.loaderService.display(false);
        if (this.authState.getStoredContextVenueId()) {
          this.getVenueFeatureConfigurations(this.authState.getStoredContextVenueId());
        }
      },
      (error) => {
        console.log(
          'venueService GetVenueById Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
        this.loaderService.display(false);
        if (this.authState.getStoredContextVenueId()) {
          this.getVenueFeatureConfigurations(this.authState.getStoredContextVenueId());
        }
      },
    );
  }

  public getVenueFeatureConfigurations(venueId: number) {
    this.loaderService.display(true);
    const CPvenue = new VenueModule();
    CPvenue.VenueCPId = this.authState.AESEncryptText(venueId);

    this.venueService.GetVenueFeatureConfigurations(CPvenue).subscribe(
      (data) => {
        this.modules = data;
        if (this.modules != null && this.modules.length > 0) {
          this.configuration.venueFeaturesConfig = [];
          this.configuration.venueFeaturesConfig = this.modules;
        } else {
          this.configuration.venueFeaturesConfig = [];
        }
        this.loaderService.display(false);
        this.getModulesData();
      },
      (error) => {
        console.log(
          'venueService GetVenueFeatureConfigurations Call Failed. Status:' +
            error.status +
            ' Status Text:' +
            error.statusText,
        );
        this.loaderService.display(false);
      },
    );
  }

  public getModulesData() {
    this.utilitiesService.isAreaMenuShow = this.utilitiesService.checkVenueFeatureConfiguration('VENUE_TAG_AREA');
  }

  public resizeStatisticsChart() {
    {
      this.loaderService.display(true);
      let svg_container: any;
      let that = this;
      const statisticsChartIds = [
        { chartName: 'locationvisitsbytime' },
        { chartName: 'locationvisitsbyweek' },
        { chartName: 'locationvisitsbymonth' },

        { chartName: 'cleaningalertbylocations' },
        { chartName: 'cleaningalertbymonths' },
        { chartName: 'cleaningalertbyweekdays' },
        { chartName: 'cleaningalertcompleted' },
        { chartName: 'taskcompleted' },
        { chartName: 'quantityofinventoryused' },

        { chartName: 'waterused' },
        { chartName: 'wateruses' },
        { chartName: 'areaWiseScore' },
        { chartName: 'buildingWiseScore' },

        { chartName: 'totalaircraftseats' },

        { chartName: 'inspectionsByLocation' },
        { chartName: 'inspectionsByMonth' },
        { chartName: 'inspectionsByWeekdays' },
        { chartName: 'inspectionsCompleted' },
        { chartName: 'elementsInspected' },

        { chartName: 'positiveNegativeSurveysByWeek' },
        { chartName: 'positiveSurveyByTime' },
        { chartName: 'negativeSurveyByTime' },
        { chartName: 'satisfactionSummaryChart' },
        { chartName: 'positiveSurveyByTime' },
        { chartName: 'negativeSurveyByTime' },
        { chartName: 'totalSurveysChart' },
        { chartName: 'negativeSurveyBreakdown' },

        // { 'chartName': '24Plot' },
        // { 'chartName': 'dayPlot' },
        // { 'chartName': 'weekendPlot' },
        // { 'chartName': 'cumulativePlot' },
        { chartName: 'throughputSurveyCount' },
        { chartName: 'throughputSurveyCountLineChart' },

        { chartName: 'inspectionperformed' },
        { chartName: 'inspectionscore' },
        { chartName: 'ticketscreated' },
      ];

      setTimeout(function () {
        for (let chart = 0; chart < statisticsChartIds.length; chart++) {
          const getChart = document.getElementById(statisticsChartIds[chart].chartName);
          if (getChart) {
            Plotly.Plots.resize(getChart);
            let window_height = window.innerHeight;
            let content_div_height = document.getElementById('chart-container').offsetHeight;
            if (content_div_height > window_height - 1) {
              svg_container = document
                .getElementById('chart-container')
                .getElementsByClassName('plot-container')[0]
                .getElementsByClassName('svg-container')[0];
              svg_container.style.height = window_height - 1 + 'px';
              Plotly.Plots.resize(getChart);
            }
          }
        }
        that.loaderService.display(false);
      }, 250);
    }
  }

  public userLogout() {
    this.loaderService.display(true);

    const userObj: Login = {
      ip_address: this.authState.getStoredIPAddress(),
      user_id: this.currentUserVal.UserId,
    };

    this.loginService.UserLogout(userObj).subscribe(
      (_data) => {
        this.authState.logout();
        this.router.navigate(['/login']);
        this.loaderService.display(false);
      },
      (error) => {
        console.log('loginService UserLogout Call Failed. Status:' + error.status + ' Status Text:' + error.statusText);
        this.loaderService.display(false);
      },
    );
  }

  checkDivVisibility(): void {
    const storedValue = this.authState.getIsEmulation();
    this.isEmulation = storedValue;
  }

  isUserEmulated(): boolean {
    const storedValue = this.authState.getIsEmulation();
    this.isEmulation = storedValue;
    return this.isEmulation;
  }

  public stopEmulation() {
    this.utilitiesService.openConfirmModal('Do you want to end the emulation?', (result: boolean) => {
      if (result) {
        this.loaderService.display(true);
        let code = this.authState.getStoredActualLoggedInUserEmail();
        this.isEmulation = false;
        this.authState.storeIsEmulation(false);
        this.loginService.EmulateUser(code).subscribe(
          (data) => {
            this.loggedInUser = data;
            this.isReqComplete = false;
            this.loaderService.display(false);

            if (this.loggedInUser.DefaultVenue) {
              this.setServiceProvider();
              this.setCorporation();
              this.setVenue();
            } else {
              this.configuration.contextServiceProviderId = 0;
              this.configuration.contextCorporationId = 0;
              this.configuration.contextVenueId = 0;
            }
            this.insertUserTokenAuthentication();

            // User Pilot for user training
            Userpilot.identify(data.Email, {
              name: data.FirstName + ' ' + data.LastName,
              email: data.Email,
              created_at: data.AddedOn,
              company: !!data.ServiceProvider // optional
                ? {
                    id: data.ServiceProvider.Ids, // Company Unique ID
                    created_at: data.ServiceProvider.AddedOn, // ISO8601 Date
                  }
                : null,
            });
          },
          (error) => {
            this.isReqComplete = false;
            this.loaderService.display(false);
            console.log('loginService login Call Failed. Status:' + error.status + ' Status Text:' + error.statusText);
          },
        );
      }
    });
  }

  /**
   * insertUserTokenAuthentication method
   */
  public insertUserTokenAuthentication() {
    this.loaderService.display(true);
    const payload: Partial<UserTokenAuthentication> = {
      ServiceProviderId: this.configuration.contextServiceProviderId,
      CorporationId: this.configuration.contextCorporationId,
      Venue: {
        VenueId: this.configuration.contextVenueId,
      },
    };

    this.loginService.InsertUserTokenAuthentication(payload).subscribe(
      () => {
        this.loaderService.display(false);
        this.setVenueDetailsAndPermissions();
      },
      (error) => {
        console.log(
          'loginService InsertUserTokenAuthentication Call Failed. Status:' +
            error.status +
            ' Status Text:' +
            error.statusText,
        );
        this.loaderService.display(false);
        this.setVenueDetailsAndPermissions();
      },
    );
  }

  /**
   * Method to fetch user permissions and set default navigation
   */
  private setRolePermissions() {
    this.loaderService.display(true);
    const searchfilter = { SearchQuery: '', GenericSearch: 0 };

    this.accessGroupPermissionService.GetAllPermissions(JSON.stringify(searchfilter), -1, -1).subscribe(
      (data) => {
        this.rolePermissions = data;
        this.loaderService.display(false);
        this.authState.isAuthenticated.next(true);
        this.store.dispatch(setUserData({ user: this.loggedInUser }));
        this.authState.setUserRolePermissions(this.rolePermissions);
        this.authState.setDefaultNavigation(this.loggedInUser, this.rolePermissions, null);
      },
      (error) => {
        this.loaderService.display(false);
        if (error.status == 401) {
          this.authState.logout();
        }
      },
    );
  }

  setVenueDetailsAndPermissions() {
    if (this.authState.getStoredContextVenueId()) {
      this.authState.getVenueById(this.authState.getStoredContextVenueId());
      this.setRolePermissions();
    } else {
      this.setRolePermissions();
    }
  }

  setServiceProvider() {
    if (this.loggedInUser.DefaultVenue.ServiceProvider) {
      this.configuration.contextServiceProviderId = this.loggedInUser.DefaultVenue.ServiceProvider.ServiceProviderId;
      this.authState.storedContextServiceProviderId(this.configuration.contextServiceProviderId);

      this.configuration.ServiceProviderName = this.loggedInUser.DefaultVenue.ServiceProvider.ServiceProviderName;
      this.authState.storedServiceProviderName(this.configuration.ServiceProviderName);
    }
  }

  /**
   * Method to set the corporation details
   */
  setCorporation() {
    if (this.loggedInUser.DefaultVenue.Corporation && this.loggedInUser.DefaultVenue.Corporation.CorporationId > 0) {
      this.configuration.contextCorporationId = this.loggedInUser.DefaultVenue.Corporation.CorporationId;
      this.authState.storedContextCorporationId(this.configuration.contextCorporationId);

      this.configuration.CorporationName = this.loggedInUser.DefaultVenue.Corporation.CorporationName;
      this.authState.storedCorporationName(this.configuration.CorporationName);
    }
  }

  /**
   * Method to set the venue details
   */
  setVenue() {
    if (this.loggedInUser.DefaultVenue.VenueId > 0) {
      this.configuration.contextVenueId = this.loggedInUser.DefaultVenue.VenueId;
      this.authState.storedContextVenueId(this.configuration.contextVenueId);

      this.configuration.VenueName = this.loggedInUser.DefaultVenue.VenueName;
      this.authState.storedVenueName(this.configuration.VenueName);
    } else {
      this.configuration.contextVenueId = 0;
    }
  }
}
