import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';

if (!environment.production) {
  console.log({ environment });
}

@Injectable({
  providedIn: 'root',
})
export class Configuration {
  public Server = environment.server;  
  public AuthServer = environment.authServer;
  public cleaningAlertRequestURL = environment.cleaningAlertRequestURL;
  public TouchlessFeedbackAppBaseURL = environment.touchlessFeedbackAppBaseURL;

  public TouchleesFeedbackEmojiURL = this.Server + 'TouchleesFeedbackEmoji/';
  public Server_Profile_Image_Folder = this.Server + 'ProfilePhotos/';
  public Server_Task_Image_Folder = this.Server + 'TaskIcons/';
  public Server_Inventory_Image_Folder = this.Server + 'InventoryIcons/';
  public Server_Asset_Image_Folder = this.Server + 'AssetImages/';
  public Server_Closure_Template_Image_Folder = this.Server + 'TicketTypeContent/';
  public Server_Closure_Workorder_Attachment = this.Server + 'WorkorderAttachment/';
  public Server_Sensor_Image_Folder = this.Server + 'SensorImage/';
  public Server_Sensor_Type_Image_Folder = this.Server + 'SensorTypeImage/';
  public Server_Import_Template = this.Server + 'ImportTemplate/ApplicationModuleImportTemplate.xlsm';
  public ReportURL = this.Server + 'UserCard.aspx?userId=' + '?venueId=';
  public Server_Element_Image_Folder = this.Server + 'ElementIcons/';
  public Server_Inspection_Attachment_Folder = this.Server + 'InspectionAttachment/';
  public Server_Digital_Display_SVG = this.Server + 'DigitalDisplayImage/';
  public Server_Digital_Display_Advertisement = this.Server + 'DigitalDisplayAdvertisement/';
  public Server_Cleaning_Alert_Attachment = this.Server + 'WorkorderCreatorAttachment/';
  public Server_Project_Work_Log_Attachment = this.Server + 'ProjectWorkAttachment/';

  public Server_Service_Provider_Image_Folder = this.Server + 'ServiceProviderLogo/';
  public Server_Corporation_Image_Folder = this.Server + 'CorporationLogo/';
  public Server_Venue_Image_Folder = this.Server + 'VenueLogo/';

  //// Throughput Custom Report URLs
  public ThroughputCustomReportURL =
    this.Server + 'Report/ThroughputCustomReport/ThroughputCustomReportView.aspx?userId=';
  public ThroughputResetSummaryReportURL =
    this.Server + 'Report/ThroughputResetSummaryReport/ThroughputResetSummaryReportView.aspx?userId=';
  public throughputCountPerDayReportURL =
    this.Server + 'Report/ThroughputCountPerDayCustomReport/ThroughputCountPerDayCustomReportView.aspx?userId=';
  public throughputCountPerHoursReportURL =
    this.Server + 'Report/ThroughputCountPerHourCustomReport/ThroughputCountPerHourCustomReportView.aspx?userId=';

  //// Feedback Custom Report URLs
  public FeedbackCustomReportURL =
    this.Server + 'Report/FeedbackSurveyCustomReport/FeedbackSurveyCustomReportView.aspx?userId=';
  public FeedbackCountCustomReportURL =
    this.Server +
    'Report/FeedbackSurveyPositiveNegativeCountsCustomReport/FeedbackSurveyPositiveNegativeCountsCustomReportView.aspx?userId=';
  public AviusFeedbackCountCustomReportURL =
    this.Server + 'Report/FeedbackAviusSurveyCustomReport/FeedbackAviusSurveyCustomReportView.aspx?userId=';

  //// Dispensor Custom Report URLs
  public DispensorsCustomReportURL =
    this.Server + 'Report/DispenserCustomReport/DispenserCustomReportView.aspx?userId=';

  //// Trash Custom Report URLs
  public TrashCustomReportURL = this.Server + 'Report/TrashCustomReport/TrashCustomReportView.aspx?userId=';

  //// Cleaning Alert Custom Report URLs
  public CleaningAlertCustomReportURL =
    this.Server + 'Report/CleaningAlertCustomReport/CleaningAlertCustomReportView.aspx?userId=';

  //// Inventory Custom Report URLs
  public InventoryCustomReportURL = this.Server + 'Report/InventoryCustomReport/InventoryCustomReportView.aspx?userId=';

  //// Task Custom Report URLs
  public TaskCustomReportURL = this.Server + 'Report/TaskCustomReport/TaskCustomReportView.aspx?userId=';

  //// Inspection Custom Report URLs
  public InspectionLogReportURL = this.Server + 'Report/InspectionLogReport/InspectionLogReportView.aspx?userId=';
  public InspectionSummeryReportURL =
    this.Server + 'Report/InspectionSummaryCustomReport/InspectionSummaryCustomReportView.aspx?userId=';
  public InspectionGoalAchievementReportURL =
    this.Server + 'Report/InspectionGoalAchievementCustomReport/InspectionGoalAchievementCustomReportView.aspx?userId=';
  public InspectorAverageScoreReportURL =
    this.Server + 'Report/InspectorAverageScoreCardCustomReport/InspectorAverageScoreCardCustomReportView.aspx?userId=';
  public AreaWiseInspectorScoreReportURL =
    this.Server + 'Report/AreaInspectionScoreCustomReport/AreaInspectionScoreCustomReportView.aspx?UserId=';

  //// Badge Custom Report URLs
  public BadgeSummaryReportURL = this.Server + 'Report/BadgeScanCustomReport/BadgeScanCustomReportView.aspx?userId=';
  public BadgeScanGoalAchievementReportURL =
    this.Server + 'Report/BadgeScanGoalAchievementCustomReport/BadgeScanGoalAchievementCustomReportView.aspx?userId=';
  public BadgeScanDailyReportURL =
    this.Server + 'Report/BadgeDailyScanCustomReport/BadgeDailyScanCustomReportView.aspx?userId=';

  //// Flush Valve Custom Report URLs
  public ZurnSmartPlumbingCustomReportURL =
    this.Server + 'Report/ZurnFlushValveCustomReport/ZurnFlushValveCustomReportView.aspx?userId=';

  //// Equipment PM Custom Report URLs
  // public EventsCustomReportURL = this.Server + 'Report/EventTypeCustomReport/EventTypeCustomReportView.aspx?userId=';
  public EventsCustomReportURL =
    this.Server + 'Report/EquipmentPMEventCustomReport/EquipmentPMEventCustomReportView.aspx?UserId=';

  //// User Login Logout Custom Report URLs
  public UserLoginActivityCustomReportURL =
    this.Server + 'Report/UserLoginActivityCustomReport/UserLoginActivityCustomReportView.aspx?userId=';

  //// User Billing Custom Report URLs
  public UserBillingCustomReportURL =
    this.Server + 'Report/UsersBillingCustomReport/UsersBillingCustomReportView.aspx?userId=';

  //// User Attendance Custom Report URLs
  public UserAttendanceReportURL =
    this.Server + 'Report/UserLocationVisitsCustomReport/UserLocationVisitsCustomReportView.aspx?userId=';

  //// Project Work Log Custom Report URLs
  public ProjectWorkLogReportURL =
    this.Server + 'Report/ProjectWorkEventCustomReport/ProjectWorkEventCustomReportView.aspx?UserId=';

  //// Calendar Schedule Custom Report URLs
  public CalendarScheduleCustomReportURL =
    this.Server + 'Report/ScheduleEventCustomReport/ScheduleEventReportView.aspx?userId=';

  public AlertTemplateAttachmentURL = this.Server + 'AlertTemplateAttachment/';

  public currentuser = document.location.pathname.replace('/', '') + 'user';
  public email = document.location.pathname.replace('/', '') + 'email';
  public token = document.location.pathname.replace('/', '') + 'token';

  public VenueId = 0;
  public Token = '';
  public isUnauthorizedState = true;
  public userVenueId = 0;
  public userZoneId = 0;

  public contextServiceProviderId = 0;
  public ServiceProviderName = '';

  public contextCorporationId = 0;
  public CorporationName = '';
  public corporationKey = 0;

  public contextVenueId = 0;
  public VenueName = '';

  public womessage: any = {};
  public isShiftStaffSchedule = 0;
  public isLocationUserAssignment = 0;
  public isEitherOneOrThese = 0;

  public pw2 = '(T4$a8eN';
  public pw1 = 'S)8#d@34';
  public currentTimezone = '';
  public pageTitle = '';
  public venueFeaturesConfig: any[] = [];

  public defaultLatitude = 41.203323;
  public defaultLongitude = -77.194527;
  public inspectNowObject: any = {};
}
