import { environment as higherEnvironment } from './environment.qa';
export const environment = {
  ...higherEnvironment,
  server: 'https://api.dev.traxinsights.app/TraxDevAPI/',
  touchlessFeedbackAppBaseURL: 'https://touchless.dev.traxinsights.app/#/',
  cleaningAlertRequestURL: 'https://ca.dev.traxinsights.app/#/',
  projectTitle: 'TRAX',
  faviconUrl: 'assets/fav_icon.ico',
  authServer: 'https://dev-app-internalapi-trax-eastus-01.azurewebsites.net/',
};
